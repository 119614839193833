import React from 'react';
import '../App.css';

function CV({ language, darkMode }) {
  return (
    <section id="cv" className={`section unselectable ${darkMode ? 'dark' : 'light'}`}>
        <h2>{language === 'en' ? 'Curriculum & Certificate' : 'Currículo & Certificado'}</h2>
        <p><a href="https://carolinac-portfolio.ddns.net/files/CarolinaCarranca_CV.pdf" download="CarolinaCarranca_CV.pdf" className="custom-link" target="_blank" rel="noopener noreferrer">Portuguese</a></p>
        {/*<p><a href="http://diogos-portfolio.ddns.net/files/DiogoSantos_CV_EN.pdf" download="DiogoSantos_CV_EN.pdf" className="custom-link" target="_blank" rel="noopener noreferrer">English</a></p>
        */}
    </section>
  );
}
export default CV;