import React from 'react';
import '../App.css';

function Footer({ language }) {
  return (
    <footer className='unselectable'>
        <div className="footer-v"><p>{language === 'en' ? 'Version: ' : 'Versão: '}<b>1.0.7</b></p></div>
        <div className="footer-cr">&copy; 2019-{new Date().getFullYear()} <a href="https://diogos-portfolio.ddns.net/" className="custom-link" target="_blank" rel="noopener noreferrer">Diogo Santos</a>. All rights reserved.</div>
        <div className="footer-up"><p>{language === 'en' ? 'Last Updated on: ' : 'Última atualização: '}<b>{language === 'en' ? '26 April 2024' : '26 Abril 2024'}</b></p></div>
    </footer>
  );
}
export default Footer;