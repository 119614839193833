import React from 'react';
import '../App.css';

function Body({ darkMode, language }) {
    return (
        <div className={`Body ${darkMode ? 'dark' : 'light'}`}>
            <section className="section unselectable">
                <div className="photo">
                    <img src="profile.png" height="246" alt=""/>
                </div>
                <h1>Carolina Carranca</h1>
                <p>{language === 'en' ? 'BSc Student in Biology' : 'Estudante de Licenciatura em Biologia'}</p>
            </section>
        </div>
    );
}

export default Body;