import React from 'react';
import '../App.css';

function Contact({ language, darkMode }) {
  return (
    <section id="contact" className={`section unselectable ${darkMode ? 'dark' : 'light'}`}>
        <h2>{language === 'en' ? 'Contact' : 'Contacto'}</h2>
        <p>Email: <a href="mailto:carolinalcarranca@gmail.com" className="custom-link" target="_blank" rel="noopener noreferrer">carolinalcarranca@gmail.com</a></p>
        <p>LinkedIn: <a href="https://www.linkedin.com/in/carolina-carranca/" className="custom-link" target="_blank" rel="noopener noreferrer">Carolina Lourenço Carranca</a></p>
    </section>
  );
}
export default Contact;