import React from 'react';
import '../App.css';

function Course({ darkMode, language }) {
  return (
    <section id="course" className={`sectionp unselectable ${darkMode ? 'dark' : 'light'}`}>
      <h2>{language === 'en' ? 'SDI Open Water Scuba Diver' : 'Mergulhador em águas abertas SDI'}</h2>
      <p><a href="https://carolinac-portfolio.ddns.net/files/Certificate.pdf" download="Certificate.pdf" className="custom-link" target="_blank" rel="noopener noreferrer">{language === 'en' ? 'Certificate' : 'Certificado'}</a></p>
    </section>
  );
}

export default Course;