import React from 'react';
import '../App.css';
import '../Header.js';
import Age from './Age.js';

function About({ language, darkMode }) {
    // State for language
    return (
      <div className={`About ${language === 'en' ? 'english' : 'portuguese'} ${darkMode ? 'dark' : 'light'}`}>
        <section id="about" className="section unselectable">
            <h2>{language === 'en' ? 'About Me' : 'Sobre Mim'}</h2>
            <p>{language === 'en' ? "I'm Carolina Carranca, I'm " : "Eu sou a Carolina Carranca, tenho "} <Age /> {language === 'en' ? " years old and I'm finishing my degree in Biology at the Faculty of Sciences of the University of Lisbon." : ' anos e estou a terminar a licenciatura em Biologia na Faculdade de Ciências da Universidade de Lisboa.'}</p>
        </section>
      </div>
    );
}
export default About;
